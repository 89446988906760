















import { Component, Watch } from 'vue-property-decorator';

import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import RolesList from '@/components/user/RolesList.vue';
import UserDataContainer from '@/components/user/UserDataContainer.vue';
import UserDataProperty from '@/components/user/UserDataProperty.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import UserCard from '@/components/user/UserCard.vue';
import ErrorMessage from '@/components/ui/error-message';
import UsersModule from '@/store/modules/users/UsersModule';
import { mixins } from 'vue-class-component';
import UserFields from '@/mixins/UserFields';

@Component({
  name: 'User',
  components: {
    Icon,
    Dropdown,
    UserDataContainer,
    UserDataProperty,
    ContextMenuSpawner,
    RolesList,
    UserCard,
    ErrorMessage,
  },
})
export default class Team extends mixins(UserFields) {
  public pageLoaded = false;
  created()
  {
    UsersModule.fetchUser(Number(this.$route.params.id)).finally(() =>
    {
      this.pageLoaded = true;
    })
  }
  @Watch('user')
  usersFetched(val:any) 
  {
    this.initializeFields()
  }
}
